<template>
  <div class="admin-currencies">
    <portal to="page-call-to-action">
      <ebp-button @click="showAddCurrency = true">{{
        $t("add-currency")
      }}</ebp-button>
    </portal>
    <advanced-data-table
      action="currencies/paginate"
      :headers="headers"
      ref="table"
      :limit="50"
    >
      <template #cell(exchange_rate_last_updated)="{ item }">{{
        fromNow(item.exchange_rate_last_updated)
      }}</template>

      <template #cell(is_primary)="{ item }">{{
        $t(item.is_primary ? "yes" : "no")
      }}</template>

      <!-- Actions -->
      <template #actions="{ item }">
        <b-button
          variant="primary"
          size="sm"
          @click="edit(item)"
          v-tooltip.top-center="$t('edit')"
        >
          <i class="bx bx-pencil"></i>
        </b-button>
        <b-button
          outlined
          variant="danger"
          size="sm"
          class="ml-1"
          @click="remove(item)"
          v-tooltip.top-center="$t('delete')"
        >
          <i class="bx bxs-trash"></i>
        </b-button>
      </template>
    </advanced-data-table>

    <!-- SalesChannel edit modal -->
    <ebp-modal :open.sync="open" :tabs="editTabs">
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #edit-currency>
        <EditCurrency :currency="editedItem || {}" @saved="added" />
      </template>
    </ebp-modal>

    <!-- SalesChannel add modal -->
    <ebp-modal :open.sync="showAddCurrency" :tabs="addTabs">
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #add-currency>
        <AddCurrency @added="added" />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";

import AddCurrency from "@/views/admin/system/currencies/Add";
import EditCurrency from "@/views/admin/system/currencies/Edit";

export default {
  name: "admin-currencies",
  components: {
    AdvancedDataTable,
    AddCurrency,
    EditCurrency
  },
  data() {
    return {
      headers: [
        "title",
        "code",
        { key: "exchange_rate", label: "exchange-rate-to-gbp" },
        {
          key: "exchange_rate_last_updated",
          label: "exchange-rate-last-updated-label"
        },
        {
          key: "is_primary",
          label: "is-primary-currency"
        }
      ],
      editedItem: null,
      saving: false,
      open: false,
      showAddCurrency: false,
      error: null,
      editTabs: ["edit-currency"],
      addTabs: ["add-currency"]
    };
  },
  methods: {
    added() {
      this.showAddCurrency = false;
      this.open = false;
      this.$refs.table.currentPage = 1;
      this.$refs.table.get();
    },
    async remove(currency) {
      if (confirm(this.$t("remove-message", { name: currency.name }))) {
        try {
          await this.$store.dispatch("currencies/delete", currency.id);
          this.$refs.table.change("id", currency.id);
        } catch (err) {
          console.error(err);
        }
      }
    },
    edit(currency) {
      this.editedItem = currency;
      this.open = true;
    }
  }
};
</script>

<style></style>
